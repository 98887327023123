import { Container, FlexBox } from "@components/wrapper"
import { Tab } from "@headlessui/react"
import { Course } from "@interfaces/course"
import { Risebinar } from "@interfaces/risebinar"
import { School } from "@interfaces/school"
import BootcampServices from "@lib/api/services/bootcamp.services"
import CourseServices from "@lib/api/services/course.services"
import RisebinarServices from "@lib/api/services/risebinar.services"
import { useScreenSize } from "@lib/hooks"
import clsx from "clsx"
import React, { useEffect, useState } from "react"
import { HomeHeading } from "../old-component/HomeHeading"
import { NewSchoolList } from "./NewSchoolList"
import { NewVideoList } from "./NewVideoList"
import { NewWorkshopList } from "./NewWorkshopList"
import { Spinner } from "@components/misc"
import { flex_center } from "@constants/styles"

interface Props {
  title: string
  subTitle: string
  link: string
}

const TitleHeader = ({ link, subTitle, title }: Props) => {
  return (
    <FlexBox justify="between" align="end" className="sm:mb-9 mb-6">
      <div className="grid sm:gap-3 sm-only:text-center sm-only:w-full">
        <h3 className={clsx("font-urbanist text-primary font-bold", "sm:text-4xl text-xl")}>
          {title}
        </h3>
        <h4 className={clsx("font-inter text-secondary-grey", "sm:text-xl text-sm")}>{subTitle}</h4>
      </div>
      <div className="underline-animation sm-only:hidden">
        <button
          onClick={() => (window.location.href = link)}
          className={clsx("font-inter text-new-blue", "sm:text-xl")}
        >
          Lihat Selengkapnya →
        </button>
      </div>
    </FlexBox>
  )
}

export const HomepageProgram = () => {
  const [isLoadingData, setIsLoadingData] = useState(true)

  const [courses, setCourses] = useState<Course[]>([])
  const [bootcamps, setBootcamps] = useState<School[]>([])
  const [proClasses, setProClasses] = useState<School[]>([])
  const [risebinars, setRisebinars] = useState<Risebinar[]>([])

  const course = new CourseServices()
  const school = new BootcampServices()
  const risebinar = new RisebinarServices()
  const { screenWidth } = useScreenSize()

  useEffect(() => {
    const getData = async () => {
      try {
        const res1 = school.getSchoolList("BOOTCAMP", 20)
        const res2 = school.getSchoolList("PRO_CLASS", 20)
        const res3 = course.getCourseList({ exclude: "coming-soon", limit: 12 })
        const res4 = risebinar.getRisebinarList()

        const [bc, pc, cl, rb] = await Promise.all([res1, res2, res3, res4])

        if (bc.isSuccess) {
          const result = bc.getValue()
          setBootcamps(result.data)
        }

        if (pc.isSuccess) {
          const result = pc.getValue()
          setProClasses(result.data)
        }

        if (cl.isSuccess) {
          const result = cl.getValue()
          setCourses(result.data)
        }

        if (rb.isSuccess) {
          const result = rb.getValue()
          setRisebinars(result.data)
        }


        setIsLoadingData(false)
      } catch (error) {
        setBootcamps([])
        setProClasses([])
        setCourses([])
        setIsLoadingData(false)
      }
    }

    getData()
  }, [])

  const TAB_LIST = [
    {
      label: "Bootcamp",
      value: "bootcamp",
      isHidden: bootcamps.length === 0,
    },
    // {
    //   label: "ProClass",
    //   value: "proclass",
    //   isHidden: proClasses.length === 0,
    // },
    {
      label: "Video Course",
      value: "video-course",
      isHidden: courses.length === 0,
    },
    {
      label: "Event & Workshop",
      value: "risebinar",
      isHidden: risebinars.length === 0,
    },
  ]

  return (
    <Container className="py-10">
      <HomeHeading headingLevel="h2" className="mb-9 text-center">
        Pilih Program Buat #JadiYangKamuMau
      </HomeHeading>

      <Tab.Group defaultIndex={0}>
        <div className="slider-mobile sm-only:w-[calc(100%+14px)] sm-only:pr-[18px]">
          <Tab.List
            className={clsx(
              "font-inter flex mx-auto w-fit border border-new-blue tab-program",
              "sm:mb-9 mb-6",
              "sm-only:w-max"
            )}
          >
            {TAB_LIST.map((category, idx) => {
              if (category.isHidden) {
                return <></>
              } else {
                return (
                  <Tab
                    key={idx}
                    className={({ selected }) =>
                      clsx(
                        "sm:w-[180px] sm-only:min-w-[100px] font-semibold rounded-none",
                        "sm:py-4 px-2.5 py-3 sm-only:text-xs",
                        selected ? "bg-new-blue text-white" : "text-new-blue bg-white",
                        category.isHidden && "hidden",
                        idx !== 0 && "border-l border-new-blue"
                      )
                    }
                  >
                    {category.label}
                  </Tab>
                )
              }
            })}
          </Tab.List>
        </div>

        {isLoadingData ? (
          <FlexBox className={clsx(flex_center,"w-full h-[400px]")}>
            <Spinner />
          </FlexBox>
        ) : (
          <Tab.Panels>
            {Boolean(bootcamps.length) && (
              <Tab.Panel>
                <TitleHeader
                  link="/school/bootcamp"
                  title="Bootcamp"
                  subTitle="Belajar. Dapet kerja. Atau uang kembali up to 110%"
                />

                <NewSchoolList data={bootcamps} screenWidth={screenWidth} type="BOOTCAMP" />
              </Tab.Panel>
            )}
            {/* {Boolean(proClasses.length) && (
              <Tab.Panel>
                <TitleHeader
                  link="/school/proclass"
                  title="ProClass"
                  subTitle="Upgrade skill buat jadi Pro Level"
                />

                <NewSchoolList data={proClasses} screenWidth={screenWidth} type="PRO_CLASS" />
              </Tab.Panel>
            )} */}
            {Boolean(courses.length) && (
              <Tab.Panel>
                <TitleHeader
                  link="/learning"
                  title="Video Course"
                  subTitle="Belajar di mana pun dan kapan pun, semaumu"
                />
                <NewVideoList courses={courses} screenWidth={screenWidth} />
              </Tab.Panel>
            )}
            {Boolean(risebinars.length) && (
              <Tab.Panel>
                <TitleHeader
                  link="/risebinar"
                  title="Event & Workshop"
                  subTitle="Pelatihan tentang berbagai topik menarik"
                />
                <NewWorkshopList data={risebinars} screenWidth={screenWidth} />
              </Tab.Panel>
            )}
          </Tab.Panels>
        )}
      </Tab.Group>
    </Container>
  )
}
