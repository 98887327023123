import { UserPopupData } from "@interfaces/ui"
import MiscServices from "@lib/api/services/misc.services"
import useInterval from "@lib/hooks/useInterval"
import clsx from "clsx"
import Image from "next/image"
import React, { useEffect, useState } from "react"
import { motion, useAnimation } from "framer-motion"

const InfiniteUserPopup = () => {
  const [dataList, setDataList] = useState<UserPopupData[]>([])
  const [showedIndex, setShowedIndex] = useState(0)

  const misc = new MiscServices()
  const controls = useAnimation()

  useInterval(() => {
    setShowedIndex(showedIndex + 1)
  }, 6000)

  useInterval(() => {
    const setAnimate = async () => {
      await controls.start({ x: -360, transition: { duration: 1 } })
      controls.start({ x: 0, transition: { duration: 1 } })
    }

    setAnimate()
  }, 5000)

  useEffect(() => {
    const getNewData = async () => {
      try {
        const res = await misc.getOrderAndAlumnus()

        if (res.isSuccess) {
          const result = res.getValue()
          setDataList(result)
          setShowedIndex(0)
        }
      } catch (error) {
        console.log(error)
      }
    }

    if (dataList.length === 0 || showedIndex === 19) {
      getNewData()
    }
  }, [showedIndex])

  if (!dataList.length || !(dataList[showedIndex])) {
    return <></>
  }

  const { name, photo, type, company_name, job_title, school_title, product_title } =
    dataList[showedIndex]

  return (
    <motion.div
      className={clsx(
        "fixed bottom-4 left-4",
        "flex gap-3 p-3 z-10",
        "bg-white sm:w-[320px] w-[70vw] rounded-10"
      )}
      animate={controls}
    >
      <div className="w-[44px] h-[44px]">
        <Image src={photo} width={44} height={44} alt={name} className="wh-full rounded-10" />
      </div>

      <div className={clsx("flex flex-col flex-1 gap-1.5", "text-xs sm-only:hidden")}>
        <div className="font-bold text-primary-black">{name}</div>
        <div>
          {type === "alumnus" && (
            <div>
              Telah berkerja sebagai <b className="text-primary-black">{job_title}</b> di{" "}
              <b className="text-primary-black">{company_name}</b>
            </div>
          )}
          {type === "order" && (
            <div>
              Baru saja mendaftar bootcamp <b className="text-primary-black">{school_title}</b>
            </div>
          )}
          {type === "digital" && (
            <div>
              Baru saja membeli produk {" "}
              <b className="text-primary-black">{product_title}</b>
            </div>
          )}
        </div>
      </div>

      <div className={clsx("text-xs sm:hidden", "w-[calc(100%-56px)]")}>
        <span className="font-bold text-primary-black">{name} </span>
         {type === "alumnus" && (
            <div>
              Telah berkerja sebagai <b className="text-primary-black">{job_title}</b> di{" "}
              <b className="text-primary-black">{company_name}</b>
            </div>
          )}
          {type === "order" && (
            <div>
              Baru saja mendaftar bootcamp <b className="text-primary-black">{school_title}</b>
            </div>
          )}
          {type === "digital" && (
            <div>
              Baru saja membeli produk{" "}
              <b className="text-primary-black">{product_title}</b>
            </div>
          )}
      </div>
    </motion.div>
  )
}

export default InfiniteUserPopup
